const ru = {
  title: 'У вас есть вопросы? Мы здесь, чтобы помочь!',
  description: 'Заполните форму ниже, и наш специалист свяжется с вами в кратчайшие сроки. Мы готовы ответить на все ваши вопросы и предложить лучшие решения для вас.',
  form: {
    name: {
      placeholder: 'Ваше имя',
    },
    email: {
      placeholder: 'Ваш Email',
    },
    phone: {
      placeholder: 'Ваш телефон',
    },
    text: {
      placeholder: 'Ваш вопрос',
    },
    button: {
      text: 'Отправить',
    },
  },
  contactsBlock: {
    title: 'Контактная информация',
    phone: 'Наш номер телефона',
    address: 'Наш адрес',
    workTime: 'Время работы',
    email: 'Наша почта',
  },
  labels: [
    'Не упустите возможность получить качественную консультацию! Заполните форму и нажмите “Отправить”. Мы ценим ваше время и гарантируем, что ответим быстро и подробно!',
    'Давайте создадим что-то уникальное вместе!',
  ]
};

export default {
  ru,
};
