declare global {
  interface Window {
    _waEmbed: (btnSettings: any) => void;
  }
}

function _waEmbed(btnSettings: any) {
  console.error('Function not implemented.');
}

export function addWhatsappButton() {
  if (!document) {
    return;
  }

  const scriptTag = document.createElement('script');
  scriptTag.src =
    'https://d2mpatx37cqexb.cloudfront.net/delightchat-whatsapp-widget/embeds/embed.min.js';
  document.head.appendChild(scriptTag);

  const btnSettings = {
    btnColor: '#16BE45',
    ctaText: '',
    cornerRadius: 40,
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
    btnPosition: 'right',
    whatsAppNumber: '79913676559',
    welcomeMessage: 'Здравствуйте! У меня есть вопрос насчёт памятника. Подскажите, пожалуйста, ...',
    zIndex: 999999,
    btnColorScheme: 'light',
  };

  scriptTag.onload = function () {
    if (!window._waEmbed) {
      return;
    }

    window._waEmbed(btnSettings);
  }
}
