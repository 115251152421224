const ru = {
  title: 'Почему выбирают нас',
  labels: [
    'Искусство на высшем уровне: Мы создаем уникальные художественные памятники и изделия, которые отражают индивидуальность и память о ваших близких.',
    'Бесплатное хранение: Ваши изделия находятся в надежных руках — мы предоставляем бесплатное хранение на нашем производстве до момента установки.',
    'Комфортный выезд мастера: Наши специалисты бесплатно выезжают на объект для консультации и оценки, чтобы предложить оптимальные решения.',
    'Индивидуальный макет: Перед началом работы мы разрабатываем детальный макет, чтобы вы точно знали, как будет выглядеть ваш памятник.',
    'Профессиональная установка: Мы осуществляем качественную установку изделий, гарантируя долгий срок службы и сохранение эстетики.',
  ],
  descriptions: [
    'Ваши воспоминания — наша забота!',
    'Доверьтесь профессионалам, и создайте памятник, который станет настоящим произведением искусства. Свяжитесь с нами, чтобы обсудить детали и получить бесплатную консультацию!',
  ],
};

export default {
  ru,
};
