const ru = {
  more: 'Все акции',
  labels: {
    time: 'Быстрое обслуживание: Мы ценим ваше время!',
    open: 'Опыт более 10 лет: Доверяйте профессионалам!',
    shield: 'Гарантия качества 100%: Мы отвечаем за каждую деталь!',
  },
  description: 'Не упустите шанс сделать важное решение с выгодой! Свяжитесь с нами уже сегодня!',
};

export default {
  ru,
};
