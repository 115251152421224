const ru = {
  title: 'СОФИЯ ГРАНИТ',
  description: 'Памятники из гранита',
  social: 'Мы в соц. сетях',
  permission: 'Перепечатка, а равно и использование материалов данного сайта, разрешается только по согласию с владельцем.',
  copyright: "СОФИЯ ГРАНИТ © 2020 - {year} {'|'} Все права защищены",
};

export default {
  ru,
};
