<template>
  <section class="about">
    <div class="container about__container">
      <div class="about__angel-bg"></div>
      <h2 class="title about__title">
        {{ $t('theAbout.title') }}
      </h2>
      <ul class="about__list">
        <li class="about__list-item"
            v-for="n in 5"
            :key="n">
          {{ $t(`theAbout.labels[${n-1}]`) }}
        </li>
      </ul>
      <div class="descriptions__list">
        <div class="descriptions__list-item"
            v-for="n in 2"
            :key="n">
          {{ $t(`theAbout.descriptions[${n-1}]`) }}
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
