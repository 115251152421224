<template>
  <section class="contacts">
    <div class="container contacts__container">
      <div class="contacts__left">
        <h2 class="title contacts__title">
          {{ $t('theContacts.title') }}
        </h2>
        <p class="contacts__form-description">
          {{ $t('theContacts.description') }}
        </p>
        <div class="contacts__labels">
          <div class="contacts__labels-item"
              v-for="n in 2"
              :key="n">
            {{ $t(`theContacts.labels[${n-1}]`) }}
          </div>
        </div>
        <div class="contacts__contact-info">
          <h2 class="title contacts__title">
            {{ $t('theContacts.contactsBlock.title') }}
          </h2>
          <table class="contacts__table">
            <tbody>
              <tr>
                <td class="contacts__table-td1">
                  {{ $t('theContacts.contactsBlock.phone') }}:
                </td>
                <td class="contacts__table-td2">
                  <template v-for="phone in contactPhones"
                            :key="phone.raw">
                    <a :href="`tel:+7${phone.raw}`"
                       class="contacts__phone decoration-none">{{ phone.formatted }}</a>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="contacts__table-td1">
                  {{ $t('theContacts.contactsBlock.address') }}:
                </td>
                <td class="contacts__table-td2">
                  <address class="contacts__address">{{ address }}
                  </address>
                </td>
              </tr>
              <tr>
                <td class="contacts__table-td1">
                  {{ $t('theContacts.contactsBlock.workTime') }}:
                </td>
                <td class="contacts__table-td2">{{ workTime }}</td>
              </tr>
              <tr>
                <td class="contacts__table-td1">
                  {{ $t('theContacts.contactsBlock.email') }}:
                </td>
                <td class="contacts__table-td2">
                  <a :href="emailHref"
                     target="blank"
                     class="contacts__email">{{ email }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ContactsForm />
    </div>
  </section>

  <section class="contacts__mobile">
    <div class="container">
      <h2 class="title contacts__title">
        {{ $t('theContacts.contactsBlock.title') }}
      </h2>
      <table class="contacts__table">
        <tbody>
          <tr class="contacts__table-tr">
            <td class="contacts__table-td1">
              {{ $t('theContacts.contactsBlock.phone') }}:
            </td>
            <td class="contacts__table-td2">
              <template v-for="phone in contactPhones"
                        :key="phone.raw">
                <a :href="`tel:+7${phone.raw}`"
                   class="contacts__phone">{{ phone.formatted }}</a>&nbsp;
              </template>
            </td>
          </tr>
          <tr class="contacts__table-tr">
            <td class="contacts__table-td1">
              {{ $t('theContacts.contactsBlock.address') }}:
            </td>
            <td class="contacts__table-td2">
              <address class="contacts__address">{{ address }}
              </address>
            </td>
          </tr>
          <tr class="contacts__table-tr">
            <td class="contacts__table-td1">
              {{ $t('theContacts.contactsBlock.workTime') }}:
            </td>
            <td class="contacts__table-td2">{{ workTime }}</td>
          </tr>
          <tr class="contacts__table-tr">
            <td class="contacts__table-td1">
              {{ $t('theContacts.contactsBlock.email') }}:
            </td>
            <td class="contacts__table-td2">
              <a :href="emailHref"
                 target="blank"
                 class="contacts__email">{{ email }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ContactsForm } from '../../ContactsForm';

const contactsStore = useContactsStore();

const {
  address = '',
  workTime = '',
  email = '',
  phones = [],
} = contactsStore.data || {};

const emailHref = `mailto:${email}`;
const contactPhones = useContactPhones(phones);
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
